import { Component, Vue } from 'vue-property-decorator'

import PublicNavbarLeis from '@/components/publicNavbarLeis/PublicNavbarLeis.vue'
import { api } from '@/services/api'

@Component({
	components:{
		PublicNavbarLeis
	}
})

export default class Lei extends Vue { 

  lei = {}

  remissaoAtivaTable = [
    {
      field: 'especie',
      label: 'Especie',
    },
    {
      field: 'lei',
      label: 'Lei',
    },
    {
      field: 'data',
      label: 'Data',
    },
    {
      field: 'acao',
      label: 'Ação',
    },
    {
      field: 'ementa',
      label: 'Ementa',
    }
  ]

  mounted() {
    this.sendGetByID(this.getUrlId());
  }

  getUrlId(){
    return this.$route.params.id;
  }

  sendGetByID(id: string){
    api.get('/leis/'+id).then( (response) => {
      console.log(response);
      this.lei = response.data;
    })
  }

}
